/* Styles for filter selects */
.filters .form-control {
  background-color: #f4f4f4; /* Light background to match the cards */
  border: 1px solid #9db2bf; /* Subtle border color */
  border-radius: 20px; /* Rounded corners to match the button */
  padding: 0.5rem; /* Reduced padding for a snug fit */
  font-size: 16px; /* Readable font size */
  margin-right: 1rem; /* Spacing between the filters */
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none; /* Removes default styling on Firefox */
  appearance: none; /* Removes default styling */
  color: #526d82; /* Text color to match your design */
  position: relative; /* Needed for custom arrow positioning */
  background: #f4f4f4
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23526D82" viewBox="0 0 20 20"><path d="M5.796 7.196L10 11.5l4.204-4.304a.5.5 0 01.796.708l-4.5 4.5a.5.5 0 01-.5.096.5.5 0 01-.5-.096l-4.5-4.5a.5.5 0 010-.708.5.5 0 01.796 0z"/></svg>')
    no-repeat right 0.75rem center / 1.25rem;
  padding-right: 2.5rem; /* Adjust padding to not overlap the background arrow */
}

/* Adjustments for mobile screens */
@media (max-width: 768px) {
  .filters .form-control {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 0.5rem; /* Add bottom margin for vertical spacing */
    width: auto; /* Auto width to fit content */
  }

  .filters .form-group {
    flex: 1; /* Each filter will take equal space */
    margin-right: 0.5rem; /* Spacing between filters */
  }

  .filters .form-group:last-child {
    margin-right: 0; /* No margin for the last filter */
  }
}

.filters {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center;
}
