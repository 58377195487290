.lost-item-report {
  font-size: small;
  color: gray;
  line-height: 1.6; /* Adjust line height for better readability */
}

.social-link {
  margin: 0 4px; /* Add horizontal space around the link */
  color: #4a90e2;
  font-weight: bold;
  text-decoration: none;
}

.social-link:hover {
  text-decoration: underline;
}

.muted-placeholder::placeholder {
  color: #6c757d; /* Muted text color, adjust as needed */
  font-size: 0.9em; /* Slightly smaller font size for subtlety */
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  border: 3px solid rgba(0, 0, 0, 0.1); /* slightly thinner border for subtlety */
  border-top: 3px solid #333; /* matching the color of the text */
  border-radius: 50%;
  width: 18px; /* slightly smaller to match the text size */
  height: 18px;
  margin-right: 5px; /* space between spinner and text */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ) !important; /* Semi-transparent black, important to override */
  backdrop-filter: blur(
    2px
  ) !important; /* Blur effect, important to override */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
  overflow-y: auto;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
  width: auto; /* Adjust based on your form size */
  max-width: 500px; /* Maximum width */
  overflow-y: auto;
  max-height: 80vh;
}

.btn-cancel {
  background-color: #dc3545 !important; /* Cancel button color, important to override */
  color: white !important;
  margin-top: 10px; /* Space from the form */
  /* other styling as needed */
}

.modal-hidden {
  display: none;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Adjust modal content for smaller screens */
@media (max-width: 576px) {
  .modal-content {
    margin: 10px; /* Adds margin around the modal */
    overflow-y: auto; /* Allows scrolling if the content is too long */
    max-height: 90vh; /* Limits the height to avoid overflow */
  }
}

@media (orientation: landscape) {
  .modal-content {
    width: 80%; /* Adjust width for landscape mode */
  }
}

/* Prevent body scroll when modal is open */
.body-no-scroll {
  overflow: hidden;
}

.modal-dialog {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-header {
  align-items: center;
  position: sticky;
}
