.mypage-container {
  padding: 1rem;
  max-width: 1200px;
  margin: auto;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.item-card {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.item-thumbnail {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-card h5 {
  margin: 0.5rem;
  font-size: 1.2rem;
}

.item-card p {
  margin: 0.5rem;
  font-size: 0.9rem;
}

.actions {
  margin-top: auto;
  padding: 0.5rem;
  text-align: center;
}

.btn {
  margin: 0.25rem;
  padding: 0.5rem 1rem;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.no-items {
  text-align: center;
  color: #6c757d;
  padding: 2rem;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .items-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .item-card {
    padding: 1rem;
  }

  .actions {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    margin: 0.25rem 0;
  }
}
