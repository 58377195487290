.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar.custom-navbar {
  background-color: #526d82 !important;
  position: sticky; /* Makes the navbar stick to the top */
  top: 0; /* Aligns the navbar to the top of the viewport */
  z-index: 1030; /* Ensures the navbar stays above other content */
}

.custom-navbar .nav-link {
  color: #ffffff; /* White for clear visibility */
  /* Remove the hover effect or adjust for mobile tap feedback */
}

.custom-navbar .navbar-brand {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 121px;
  padding: 1.5px 8px;
  margin-right: 15px;
  text-decoration: none;
}

.custom-navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

:root {
  --bs-btn-border-color: #526d82;
}
