/* Container setup */
.container {
  padding: 1rem;
}

/* Top bar and filters */
.top-bar,
.menu-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.button-and-accordion-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
  /* Center children horizontally */
}

/* Centered button group */
.btn-group-centered {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 0.5rem; /* Space between buttons */
  margin-bottom: 1rem; /* Space between buttons and accordion content */
}

/* Accordion content styling */
.accordion-content {
  width: 100%; /* Accordion content takes full width */
  /* Additional styling as needed */
}

/* Button styling */
.btn-add-new,
.btn-cancel {
  display: inline-block; /* Align with inline elements */
  padding: 0.5rem 1rem; /* Vertical and horizontal padding */
  font-size: 1rem; /* Font size */
  white-space: nowrap; /* Prevents the text from wrapping */

  text-overflow: ellipsis; /* Adds an ellipsis if the content is too long */
  border: none; /* Removes the border */
  border-radius: 50rem; /* Gives a large radius to create a pill shape */
  background-color: #007bff; /* Background color */
  color: white; /* Text color */
  cursor: pointer; /* Changes the cursor to signify this is a clickable item */
}

/* For cancel button, just change the background-color */
.btn-cancel {
  display: inline-block; /* Align with inline elements */
  padding: 0.5rem 1rem; /* Vertical and horizontal padding */
  font-size: 1rem; /* Font size */
  white-space: nowrap; /* Prevents the text from wrapping */

  text-overflow: ellipsis; /* Adds an ellipsis if the content is too long */
  border: none; /* Removes the border */
  border-radius: 50rem; /* Gives a large radius to create a pill shape */
  background-color: #007bff; /* Background color */
  color: white; /* Text color */
  cursor: pointer; /* Changes the cursor to signify this is a clickable item */
}

/* Centered button group */
.btn-group-centered {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 0.5rem; /* Space between buttons */
  margin-bottom: 1rem; /* Space between buttons and accordion content */
  flex-wrap: nowrap; /* Prevent buttons from wrapping */
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .btn-group-centered {
    justify-content: center;
  }
}

/* Ensure the button group does not stretch to the full width on larger screens */
@media (min-width: 768px) {
  .btn-group-centered {
    justify-content: center;
    flex-wrap: wrap; /* Allow buttons to wrap to the next line if needed */
  }
}

/* Card styles */
.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  height: 200px;
  object-fit: cover; /* Make sure images cover the area */
}

.card.custom-card {
  margin-top: -23px;
}

.card-body {
  padding: 1rem;
  text-align: center;
}

.card-title {
  font-size: 1.25rem;
  color: #333;
}

.card-text {
  font-size: 1rem;
  color: #666;
}

/* Found label */
.lost-label {
  display: block;
  background-color: #4caf50; /* Green color for found items */
  color: white;
  text-align: center;
  padding: 0.5rem;
  border-radius: 10px 10px 0 0; /* Top rounded corners */
}

/* User info and contact button */
.user-info {
  padding: 1rem;
  text-align: center;
  color: #526d82 !important;
  border-color: #526d82 !important;
  border-radius: 20px;
  border: 2px;
}

.btn-outline-primary {
  color: #526d82 !important;
  border-color: #526d82 !important;
  border-radius: 20px;
  padding: 0.25rem 1rem;
}

.btn-outline-primary:hover {
  background-color: #526d82;
  color: white;
}

/* Pagination */
.pagination {
  justify-content: center;
  padding-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .top-bar {
    flex-direction: column;
  }

  .search-bar,
  .filters {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .btn-add-new2 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .card {
    margin-bottom: 2rem;
  }

  .card-img-top {
    height: auto;
  }
}

.btn-add-new {
  border: 1px solid red !important; /* Example: solid blue border */
  /* Other button styling */
  color: red !important;
  text-wrap: nowrap;
}

.btn-add-filter {
  border: 1px solid #526d82 !important; /* Example: solid blue border */
  /* Other button styling */
  color: #526d82 !important;
  text-wrap: nowrap;
}

.modal {
  z-index: 1000; /* Adjust accordingly */
  /* ... other styles ... */
}

.view-more-button {
  background-color: #526d82; /* Primary color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 15px; /* Padding inside the button */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor to indicate it's clickable */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
  margin-top: 10px; /* Space above the button */
}

.view-more-button:hover {
  background-color: darken(#526d82, 10%); /* Darken on hover for feedback */
}
