/* Pagination.css */

/* General styles for pagination */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination .page-item {
  margin: 0 2px; /* Spacing between page items */
}

.pagination .page-item .page-link {
  border-radius: 50px; /* Rounded corners for the page links */
  border: 1px solid #9db2bf; /* Subtle border color */
  color: #526d82; /* Text color to match your design */
  background-color: #f4f4f4; /* Light background to match the cards */
  padding: 0.5rem 1rem; /* Comfortable padding */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

/* Styles for the active page item */
.pagination .page-item.active .page-link {
  background-color: #526d82; /* Background color for active page */
  color: #ffffff; /* Text color for active page */
}

/* Hover styles */
.pagination .page-item .page-link:hover {
  background-color: #9db2bf; /* Slightly darker background on hover */
  color: #ffffff; /* White text color on hover */
}

/* Disabled styles */
.pagination .page-item.disabled .page-link {
  cursor: not-allowed;
  opacity: 0.6; /* Tone down the disabled link */
}
