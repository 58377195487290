.item-details-modal {
  background-color: #f8f8f8;
  color: #526d82;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 500px;
  margin: auto; /* Center the modal */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  min-width: 330px;
}

.item-details-modal h3 {
  color: #526d82;
  border-bottom: 2px solid #526d82;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.item-details-modal h5 {
  margin-top: 20px;
  color: #526d82;
  font-weight: normal;
}

.item-details-modal p {
  /* background-color: white; */
  padding: 10px;
  border-radius: 5px;
  /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.item-details-modal button {
  background-color: #526d82;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.item-details-modal button:hover {
  background-color: darken(#526d82, 10%);
}

.item-details-modal .modal-close-button {
  position: absolute;
  top: -34px; /* Position it slightly above the modal */
  right: -10px; /* Position it slightly to the right of the modal */
  border: none;
  background-color: #526d82; /* Match your color scheme */
  color: white;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%; /* Circular shape */
  width: 30px; /* Fixed width */
  height: 30px; /* Fixed height */
  text-align: center; /* Center the 'X' */
  line-height: 30px; /* Vertically center the 'X' */
  padding: 0; /* Remove padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Adds a shadow for depth */
}

.item-details-modal .modal-close-button:hover {
  background-color: darken(#526d82, 10%); /* Darken on hover for feedback */
}

.item-details-modal .source-admin {
  color: red;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}
