/* AlertBanner.css */
.alert-banner {
  position: fixed;
  bottom: 0; /* Stick to the bottom */
  left: 0;
  width: 100%;
  background-color: #ff9800; /* Example: Orange background for visibility */
  color: white;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* High z-index to stay on top */
}

.alert-banner p {
  margin: 0;
  font-size: 16px;
}

.alert-banner a {
  color: #4a90e2; /* Link color */
  text-decoration: none;
  font-weight: bold;
}

.alert-banner a:hover {
  text-decoration: underline;
}
