.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal.open {
  display: flex;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* More styling as needed */
}

.blur-background {
  filter: blur(5px); /* Adjust the blur intensity as needed */
}
