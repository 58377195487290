@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");

.home-container {
  padding: 20px;
  text-align: center;
  background-color: #dde6ed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use the full height of the viewport */
  width: 100%; /* Full width */
}

.home-viber-icon {
  position: absolute;
  left: 50%;
  bottom: 93px;
  transform: translateX(-50%);
  width: 233px;
  height: 57px;
}

.home-title {
  color: #333; /* A dark color for the text */
  font-size: 63px; /* A suitable font size for mobile readability */
  margin-bottom: 10px;
  font-family: "Caveat", cursive;
}

.home-subtitle {
  color: #666; /* A slightly lighter color for the subtitle */
  font-size: 18px;
  margin-bottom: 20px;
}

.home-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.home-button {
  padding: 10px 15px; /* Sufficient padding for tap targets */
  border-radius: 25px; /* A fully rounded shape */
  font-size: 16px; /* Readable font size */
  text-decoration: none;
  color: #fff; /* White text for contrast */
  width: fit-content; /* Only as wide as the content plus padding */
  margin-bottom: 10px; /* Space between buttons */
  transition: background-color 0.3s ease; /* Smooth transition for color */
}

.home-button-lost {
  background-color: #007bff; /* Blue for lost items */
}

.home-button-found {
  background-color: #28a745; /* Green for found items */
}

.home-button-join {
  background-color: #6c757d; /* Grey for join us */
}

/* Adding hover effects for non-touch devices */
@media (hover: hover) {
  .home-button:hover {
    opacity: 0.8; /* Slight transparency on hover for feedback */
  }
}

/* Adding media query for larger screens */
@media (min-width: 768px) {
  .home-container {
    max-width: 500px; /* Max-width to prevent stretching on wider screens */
    margin: auto; /* Center the container */
    height: auto; /* Height auto for larger screens */
  }
}

.inline-buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 10px; /* Adjust the space between the buttons */
  margin-bottom: 20px; /* Space between this set and the next button */
}

/* Style for buttons that should appear inline */
.inline-button {
  white-space: nowrap; /* Prevents the text from wrapping */
  padding: 10px 20px; /* Sufficient padding */
  border-radius: 25px; /* Fully rounded shape */
  font-size: 16px; /* Readable font size */
  text-decoration: none;
  color: #fff; /* White text for contrast */
  background-color: #9db2bf; /* Example color - change as needed */
  border: none;
  transition: background-color 0.3s ease; /* Smooth transition for color */
  margin: 0; /* Remove any default margin */
}

/* Adjusting the join us button to be full width and centered */
.join-us-button {
  display: inline-block; /* Allows the button to adjust to text size */
  white-space: nowrap; /* Prevents the text from wrapping */
  padding: 10px 20px; /* Sufficient padding */
  border-radius: 25px; /* Fully rounded shape */
  font-size: 16px; /* Readable font size */
  text-decoration: none;
  color: #fff; /* White text for contrast */
  background-color: #9db2bf; /* Grey background for 'Join Us' */
  transition: background-color 0.3s ease; /* Smooth transition for color */
  margin-top: 20px; /* Space above the button */
}

/* Ensuring buttons do not grow beyond the text width */
.inline-button,
.join-us-button {
  max-width: max-content; /* Button width is as wide as the content allows */
}

.join-facebook-button {
  display: inline-block;
  white-space: nowrap;
  padding: 10px 20px;
  border-radius: 25px; /* Fully rounded corners */
  font-size: 16px;
  text-decoration: none;
  color: #fff; /* White text color */
  background-color: #4267b2; /* Facebook brand color */
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Space above the button */
  text-align: center;
  vertical-align: middle;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.join-facebook-button:hover {
  background-color: #365899; /* Slightly darker blue on hover for feedback */
}

.join-viber-button {
  display: inline-flex; /* Use flexbox for alignment */
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center content horizontally */
  white-space: nowrap;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  background-color: #665cac; /* Viber color */
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.join-viber-button:hover {
  background-color: #564f8a; /* Darker on hover */
}

.join-viber-button .fab {
  /* FontAwesome icon styling */
  margin-right: 10px; /* Space between icon and text */
}

/* Add this class to your Home.css file */
.button-icon {
  margin-right: 8px; /* Adjust the space as needed */
}
